import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useLocation, useNavigate } from "react-router-dom";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import apiClient from "../../../axios";
import SuccessProcess from '../SuccessProcess';
import LoginNotification from "../LoginNotification";

const ModalStatus = ({ message, isVisible, onClose, modulo, activo }) => {
  const [successVisible, setSuccessVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [message2, setMessage2] = useState('');
  const [expirationDate, setExpirationDate] = useState(null);
  const [reason, setReason] = useState('');

  const handleDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    setExpirationDate(formattedDate);
  };
  console.log(activo);
  const handleConfirm = async () => {
    if (activo) {
      const payloadBlock = {
        reason,
        expirationDate
      };
      try {
        await apiClient.post(`/modules/${modulo}/block`, payloadBlock);
        setSuccessVisible(true);
        setMessage2('Módulo desactivado exitosamente.');
      } catch (error) {
        setMessage2('Ocurrió un error al actualizar el módulo.');
        if (error.response.data.error === "Unauthorized") {
          setIsInfoVisible(true);
        }
      }

    } else {
      try {
        await apiClient.post(`/modules/${modulo}/unblock`);
        setSuccessVisible(true);
        setMessage2('Módulo activado exitosamente.');
      } catch (error) {
        setMessage2('Ocurrió un error al actualizar el módulo.');
        if (error.response.data.error === "Unauthorized") {
          setIsInfoVisible(true);
        }
      }
    }
  };

  const handleCloseSuccess = () => {
    setSuccessVisible(false);
    onClose();
  };

  return (
    isVisible && (
      <div className="info-notification-info-overlay">
        <div className={activo ? "info-notification-info2" : "info-notification-info"}>
          <div className="icon-container-noti-info">
            <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
          </div>
          <div className="message-container-info">
            <h4>Información</h4>
            <p>{message}</p>
          </div>
          {activo ? (
            <>
              <div className="message-container-info">
                <div className="basic-info-form-group">
                  <label>Caduca</label>
                  <div className="basic-info-date-picker">
                    <DatePicker
                      selected={expirationDate ? new Date(expirationDate) : null}
                      onChange={handleDateChange}
                      showTimeSelect
                      locale={es}
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      placeholderText="Selecciona la Fecha"
                      className="custom-date-picker"
                    />
                  </div>
                </div>
              </div>
              <div className="message-container-info">
                <div className="basic-info-form-group">
                  <label>Bloqueo</label>
                  <input
                    type="text"
                    placeholder="Comentario"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              
            </div>
          )}

          <div className="button-container-info">
            <button className="cancel-btn-info" onClick={onClose}>
              Cancelar
            </button>
            <button className="ok-btn-info" onClick={handleConfirm}>
              OK
            </button>
          </div>
          <SuccessProcess
            message={message2}
            isVisible={successVisible}
            onClose={handleCloseSuccess}
          />
          <LoginNotification
            message="Vuele a iniciar sesión"
            isVisible={isInfoVisible}
            onClose={() => setIsInfoVisible(false)}
          />
        </div>
      </div>
    )
  );
};

export default ModalStatus;
