import React, { useState, useEffect, useCallback } from 'react';
import './Contract.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, Table } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileContract, faFileCirclePlus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";

const Contract = ({ handleLogout }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const location = useLocation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(''); // Estado definitivo para la búsqueda
  // const [customer, setCustomer] = useState(location.state?.customer.code || ''); // Estado definitivo para la búsqueda
  const [customer] = useState(location.state?.customer.code || ''); // Estado definitivo para la búsqueda
  const [cif] = useState(location.state?.customer.cif || ''); // Estado definitivo para la búsqueda
  const [name] = useState(location.state?.customer.socialReason || location.state?.customer.comercialName || ''); // Estado definitivo para la búsqueda
  const [totalItems, setTotalItems] = useState(0);
  const [tempSearch, setTempSearch] = useState(''); // Temporal para el input de contrato
  // const [tempCustomers, setTempCustomers] = useState(''); // Temporal para el input de cliente
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);

  // Definición de la función handleRowClick
  const handleRowClick = (item) => {
    setSelectedRow(item);
    // Aquí puedes agregar cualquier lógica adicional que necesites cuando se haga clic en una fila
  };

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };

  // Función de búsqueda
  const handleSearch = useCallback(async (page = 1) => {

    try {
      let endPoint = 'clients';
      setLoading(true)
      if (search) {
        endPoint += `/${customer}/contracts?page=${page}&search=${search}`;
      } else {
        endPoint += `/${customer}/contracts?&page=${page}`;
      }

      const response = await apiClient.get(endPoint);
      if (Array.isArray(response.data.results)) {
        setData(response.data.results);
      } else {
        setData([response.data.results]);
      }
      setTotalItems(response.data.total);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response.data.error === "Unauthorized") {
        setIsInfoVisible(true);
      }
    }
  }, [search, customer]);

  // Ejecuta handleSearch cuando el componente se monta
  useEffect(() => {
    handleSearch(currentPage);
  }, [handleSearch, currentPage]);

  // Asigna los valores definitivos y ejecuta la búsqueda
  const assignSearchValues = () => {
    if (tempSearch) {
      setSearch(tempSearch);
    } else {
      setSearch(tempSearch);
    }
    handleSearch(1);
  };

  const onRefreshThis = () => {
    setTempSearch('');
    setSearch('');
    handleSearch(1);
  };

  const columns = [
    { title: "Número de contrato", key: "numCont" },
    { title: "Cliente", key: "cliente" },
    { title: "Cédula", key: "cif" },
    { title: "Descripción", key: "descripcion" },
    { title: "Acciones", key: "acciones" },
  ];

  const renderRow = (item, index) => (
    <>
      <td onClick={() => handleRowClick(item)}>{item?.numCont}</td>
      <td onClick={() => handleRowClick(item)}>{item?.cliente}</td>
      <td onClick={() => handleRowClick(item)}>{item?.cif}</td>
      <td onClick={() => handleRowClick(item)}>{item?.descripcion}</td>
      <td>
        <div className="button-container">
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('CONTRACT_VIEW') && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Detalles"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/ContractEdition', { state: { numCont: item?.numCont } });
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          )}
        </div>
      </td>
    </>
  );

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title='Contratos' />
      <div className="home-content">
        <Section>
          <div className="filter-form">
            <div className="form-group-contract ">
              <input
                className="contract-input"
                type="text"
                id="search"
                value={tempSearch}
                onChange={(e) => {
                  setTempSearch(e.target.value);
                }}
                placeholder="Descripción"
              />
            </div>
          </div>
          <div className="button-add">
            <button
              className="basic-custom-button"
              onClick={() => assignSearchValues()}
            >
              <FontAwesomeIcon className="basic-shortcut-icon" icon={faSearchPlus} />
              Buscar
            </button>
          </div>
          <div className="button-add-contract">
            
              <div className="button-add-contract">
                {hasPermission('CONTRACT_CREATE') && (
                  <button
                    className="basic-contract-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('/ContractNew', { state: { contract: data, cif: customer } });
                    }}
                  >
                    <FontAwesomeIcon className="basic-shortcut-icon" icon={faFileCirclePlus} />
                    Crear Nuevo Contrato
                  </button>
                )}
              </div>
   
          </div>
        </Section>
        <Table
          title={`Lista de Contratos del Cliente (${name}) con cédula (${cif})`}
          rows={data}
          columns={columns}
          icon={faFileContract}
          renderRow={renderRow}
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          selectedRow={selectedRow}
          onRefresh={onRefreshThis}
          loading={loading}
        />
      </div>
      <SuccessNotification
        message={"Datos cargados correctamente"}
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      <ErrorNotification
        message="Ups! Ocurrio un Problema"
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isInfoVisible}
        onClose={() => setIsInfoVisible(false)}
      />
    </div>
  );
};

export default Contract;
