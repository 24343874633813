import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LayoutSidebar } from './components'; // Importa el componente Layout
import { Login, Contract, Module, HomePage, ModuleList, ClientForm, ModulesForm, Customers, ModuleNew, ContractNew, ModuleContract, ContratAll, ContractEdition } from "./Pages";

function App() {

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to="/HomePage" />
            )
          }
        />
        <Route
          path="/Home"
          element={
            isAuthenticated ? (
              <LayoutSidebar><HomePage handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('CONTRACT_LIST') && (
          <Route
            path="/Contract"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Contract handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route
          path="/ContractAll"
          element={
            isAuthenticated ? (
              <LayoutSidebar><ContratAll handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('CONTRACT_CREATE') && (
          <Route
            path="/ContractNew"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MODULE_UPDATE', 'MODULE_CREATE') && (
          <Route
            path="/ModuleContract/*"
            element={
              isAuthenticated ? (
                <LayoutSidebar>
                  <ModuleContract handleLogout={handleLogout} />
                </LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_CREATE') && (
          <Route
            path="/ClientForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ClientForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_CREATE','CUSTOMER_UPDATE') && (
          <Route path="/ClientForm/:customerCode"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ClientForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PRODUCT_LIST') && (
          <Route
            path="/Module"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Module handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CUSTOMER_LIST') && (
          <Route
            path="/Customers"
            element={
              isAuthenticated ? (
                <LayoutSidebar><Customers handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('MODULE_LIST') && (
          <Route
            path="/ModulesForm"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModulesForm handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route
          path="/ModuleList"
          element={
            isAuthenticated ? (
              <LayoutSidebar><ModuleList handleLogout={handleLogout} /></LayoutSidebar>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {hasPermission('PRODUCT_CREATE') && (
          <Route
            path="/ModuleNew"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModuleNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('PRODUCT_UPDATE', 'PRODUCT_CREATE') && (
          <Route path="/ModuleNew/:productId"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ModuleNew handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        {hasPermission('CONTRACT_VIEW') && (
          <Route path="/ContractEdition"
            element={
              isAuthenticated ? (
                <LayoutSidebar><ContractEdition handleLogout={handleLogout} /></LayoutSidebar>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        )}
        <Route path="*" element={<Navigate to="/Home" />} />
      </Routes>
    </Router>
  );
}

export default App;
