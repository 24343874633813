import React, { useState } from 'react';
import './ContractStatus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale";
import { format } from "date-fns";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import apiClient from "../../../axios";
import SuccessProcess from '../SuccessProcess';
import LoginNotification from "../LoginNotification";
import ErrorNotification from '../ErrorNotification';

const ContractStatus = ({ message, isVisible, onClose, numContId, block }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [message2, setMessage2] = useState('');
    const [expirationDate, setExpirationDate] = useState(null);
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});

    const handleDateChange = (date) => {
        // Convierte la fecha seleccionada al formato YYYY-MM-DD HH:mm:ss
        const formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
        setExpirationDate(formattedDate);
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!expirationDate) validationErrors.expirationDate = "El Campo es Obligatorio";
        if (!reason) validationErrors.reason = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleConfirm = async () => {

        if (block) {
            //ACTIVAR
            try {
                await apiClient.post(`/contracts/${numContId}/unblock`);
                setSuccessVisible(true);
                setMessage2('Contrato activado exitosamente.');
            } catch (error) {
                setMessage2('Ocurrió un error al actualizar el Contrato.');
                if (error.response.data.error === "Unauthorized") {
                    setIsInfoVisible(true);
                }
            }

        }
        else {
            const validationErrors = validateFields();
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                return;
            }
            //BLOQUEAR
            const payloadBlock = {
                reason,
                expirationDate
            };
            try {
                await apiClient.post(`/contracts/${numContId}/block`, payloadBlock);
                setSuccessVisible(true);
                setMessage2('Contrato desactivado exitosamente.');
            } catch (error) {
                setMessage2('Ocurrió un error al actualizar el Contrato.');
                if (error.response.data.error === "Unauthorized") {
                    setIsInfoVisible(true);
                }
            }

        }
    };

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className={!block ? "info-notification-info2" : "info-notification-info"}>
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon-noti2" />
                    </div>
                    <div className="message-container-info">
                        <h4>Información</h4>
                        <p>{message}</p>
                    </div>
                    <div className="message-container-info">
                        {!block && (
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.expirationDate ? "red" : "inherit" }}>Caduca</label>
                                <div className="basic-info-date-picker">
                                    <DatePicker
                                        selected={expirationDate ? new Date(expirationDate) : null}
                                        onChange={handleDateChange}
                                        showTimeSelect
                                        locale={es}
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        placeholderText="Selecciona la Fecha"
                                        className="custom-date-picker"
                                    />
                                </div>
                                {errors.expirationDate && (
                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                        {errors.expirationDate}
                                    </p>
                                )}

                            </div>
                        )}
                    </div>
                    <div className="message-container-info">
                        {!block && (
                            <div className="basic-info-form-group">
                                <label style={{ color: errors.reason ? "red" : "inherit" }}>Bloqueo</label>
                                <input
                                    type="text"
                                    placeholder="Comentario"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                                {errors.reason && (
                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                        {errors.reason}
                                    </p>
                                )}

                            </div>
                        )}
                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    <SuccessProcess
                        message={message2}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default ContractStatus;
